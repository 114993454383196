<template>
  <div class="container">
    <div class="options">
      <div class="option" @click="useSlectedTool">选择工具</div>
      <div class="option" @click="usePencilBrush">铅笔</div>
      <div class="option" @click="useEraserBrush">橡皮擦</div>
      <div class="option" @click="useSquare">正方形</div>
      <div class="option" @click="useCircle">圆形</div>
      <div class="option" @click="useLine">线段</div>
      <div class="option" @click="usePath">钢笔</div>
      <div class="option" @click="useText">文本</div>
      <div class="option" @click="reset">重置画布</div>
      <div class="option" @click="getObjects">获取对象</div>
    </div>
    <canvas width="600" height="600" id="c"></canvas>
    <div class="attributes"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { fabric } from "fabric-with-erasing"; // 引入 fabric
export default {
  name: "HomeView",
  mounted() {
    this.init();
  },
  data() {
    return {
      c: {},
    };
  },
  methods: {
    init() {
      // 这里传入的是canvas的id
      let zoom, zoomPoint;
      this.c = new fabric.Canvas("c", {
        width: 600,
        height: 600,
      });
      this.c.on({
        // 鼠标滚动缩放
        "mouse:wheel": (e) => {
          zoom = (event.deltaY > 0 ? -0.1 : 0.1) + this.c.getZoom();
          zoom = Math.max(0.1, zoom); //最小为原来的1/10
          zoom = Math.min(3, zoom); //最大是原来的3倍
          //	zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y);
          zoomPoint = new fabric.Point(400, 400); // 中心点
          this.c.zoomToPoint(zoomPoint, zoom);
        },
      });

      // 禁止画笔涂抹范围超出当前画布可视范围
      this.c.freeDrawingBrush.limitedToCanvasSize = true;
    },

    usePencilBrush() {
      this.removeMouseListener();
      this.c.skipTargetFind = true;
      this.c.isDrawingMode = true;
      const pencilBrush = new fabric.PencilBrush(this.c);
      this.c.freeDrawingBrush = pencilBrush;
    },

    useEraserBrush() {
      this.removeMouseListener();
      this.c.isDrawingMode = true;
      this.c.freeDrawingBrush = new fabric.EraserBrush(this.c);
      this.c.freeDrawingBrush.width = 10;
      this.c.freeDrawingBrush.color = "#FFF";
      console.log("aa", this.c.getObjects());
    },

    removeMouseListener() {
      this.c.off("mouse:down");
      this.c.off("mouse:up");
      this.c.off("mouse:move");
    },

    useSquare() {
      // 移除所有事件
      this.removeMouseListener();
      let originX,
        originY,
        currentSquare,
        pre = null,
        isDown = false;
      this.c.skipTargetFind = true;
      this.c.isDrawingMode = false;
      // 记录当前按下鼠标位置，并生成正方形对象
      this.c.on("mouse:down", (e) => {
        originX = e.pointer.x;
        originY = e.pointer.y;
        isDown = true;
        const rect = new fabric.Rect({
          top: originX, // 距离容器顶部 30px
          left: originY, // 距离容器左侧 30px
          width: 0, // 宽 100px
          height: 0, // 高 60px
          fill: "transparent",
          stroke: "rgba(0, 0, 0, 0.2)",
        });
        currentSquare = rect;
        this.c.add(rect);
      });

      // 按下鼠标拖动时 会生成一个拖动正方形
      this.c.on("mouse:move", (e) => {
        if (isDown) {
          let width = Math.abs(e.pointer.x - originX);
          let height = Math.abs(e.pointer.y - originY);
          let left = e.pointer.x > originX ? originX : e.pointer.x;
          let top = e.pointer.y > originY ? originY : e.pointer.y;
          currentSquare.set("left", left);
          currentSquare.set("top", top);
          currentSquare.set("width", width);
          currentSquare.set("height", height);
          this.c.requestRenderAll();
        }
      });
      // 正式生成正方形
      this.c.on("mouse:up", (e) => {
        isDown = false;
        currentSquare.set("stroke", "#000");
        currentSquare = null;
      });
    },
    // 划线
    useLine() {
      this.removeMouseListener();
      //   c.defaultCursor = "crosshair"; //默认光标改成十字
      let currentLine = null,
        isDown = false,
        originX,
        originY,
        pre;
      // 按下鼠标画布设置为不可选择，设置isdown ，记录下鼠标位置，pre对象是保留上一次绘画的对象
      this.c.on("mouse:down", (e) => {
        this.c.skipTargetFind = true;
        isDown = true;
        originX = e.pointer.x;
        originY = e.pointer.y;
      });
      // 拖动鼠标生成临时线
      this.c.on("mouse:move", (e) => {
        if (isDown) {
          if (pre) {
            this.c.remove(pre);
          }
          // 划线
          const line = new fabric.Line(
            [originX, originY, e.pointer.x, e.pointer.y],
            {
              stroke: "#000", // 笔触颜色
            }
          );

          this.c.add(line);
          pre = line;
        }
      });
      // 鼠标弹起设置isdown为false并且设置pre对象为null
      this.c.on("mouse:up", (e) => {
        isDown = false;
        pre = null;
        // removeMouseListener();
      });
    },

    // 不存在选择工具，使用方法是移除所有事件，并且将画布设置不可绘画 可选择
    useSlectedTool() {
      this.removeMouseListener();
      this.c.skipTargetFind = false;
      this.c.isDrawingMode = false;
    },

    // 参考：https://juejin.cn/post/6897134376312635406
    // 钢笔工具
    // 思想思路： 点击生成圆点，并将圆点对象存入数组，圆点对象应该保存划线的类型 如 M 移动 Q 贝塞尔 L 直线，在鼠标按下移动事件中绘画贝塞尔曲线
    //  在鼠标按下事件触发的时候在画布上画一个圆，位置设置为鼠标的x和y，设置将要画的线段类型 (M：移动到xy,L：划直线到xy,Q：画贝塞尔曲线)，将圆的对象存入一个数组。
    //  按下鼠标并进行移动则绘画贝塞尔曲线绘画，临时变动的贝塞尔曲线，并且绘画2条拖动曲线，具体使用查看 fabric.js官方文档
    // 鼠标弹起根据数组生成字符串，然后在进行路径对象生成
    usePath() {
      let pointArray = [],
        isDown = false,
        isBezier = false;
      let path,
        bezierObj,
        group,
        str = "",
        mouseLine1,
        mouseLine2,
        lastIndex = 0,
        circle,
        isSelected = false;
      this.c.on("mouse:down", (e) => {
        // 生成一个id用来唯一标识
        let id = new Date().getTime() + Math.random();
        // L 代表线的类型是直线
        let lineType = "L";
        // 画一个圆
        circle = new fabric.Circle({
          radius: 3,
          fill: "#1a80ff",
          left: e.pointer.x,
          top: e.pointer.y,
          originX: "center",
          originY: "center",
          id,
          lineType: "L",
        });
        // 把圆添加到画布上显示
        this.c.add(circle);
        // 把圆添加进数组方便后续操作
        pointArray.push(circle);
        console.log("pointArray", pointArray);
        // 标识按下了按钮，如果false则不会触发mouse:move
        isDown = true;
        // 如果是第一个圆点则类型是移动 M
        if (pointArray.length <= 1) {
          pointArray[0].lineType = "M";
        } else {
          // 否则就是直线
          pointArray[pointArray.length - 1].lineType = "L";
        }
      });
      this.c.on("mouse:move", (e) => {
        // console.log("pos", e.pointer.x, e.pointer.y);
        if (isDown) {
          // 设置控制点的坐标
          let x = 2 * pointArray[pointArray.length - 1].left - e.pointer.x;
          let y = 2 * pointArray[pointArray.length - 1].top - e.pointer.y;
          console.log("moved");
          // 确定该绘画曲线是贝塞尔
          isBezier = true;
          // 生成贝塞尔曲线的圆对象
          bezierObj = new fabric.Circle({
            radius: 3,
            fill: "#1a80ff",
            left: x,
            top: y,
            originX: "center",
            originY: "center",
            lineType: "Q",
            id: new Date().getTime() + Math.random(),
          });
          let len = pointArray.length;
          let s =
            " " +
            "M" +
            " " +
            pointArray[len - 2].left +
            " " +
            pointArray[len - 2].top +
            " " +
            "Q" +
            " " +
            bezierObj.left +
            " " +
            bezierObj.top +
            " " +
            pointArray[len - 1].left +
            " " +
            pointArray[len - 1].top;
          // 清理上一次绘画的线
          if (path) {
            this.c.remove(path);
          }
          // 画线
          path = new fabric.Path(str + s);
          path.set({
            fill: "transparent",
            stroke: "#1a80ff",
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
          });
          // 添加进画布
          this.c.add(path);

          this.c.selection = true;
          // new fabric.Line(x1,y1,x2,y2)  x1,y1是起始点,x2,y2是结束点
          // mouseline1是鼠标按下拖动时出现的控制线
          if (mouseLine1) {
            this.c.remove(mouseLine1);
          }
          mouseLine1 = new fabric.Line(
            [
              pointArray[len - 1].left,
              pointArray[len - 1].top,
              e.pointer.x,
              e.pointer.y,
            ],
            {
              strokeWidth: 2,
              fill: "#999999",
              stroke: "#999999",
              class: "line",
              originX: "center",
              originY: "center",
              selectable: false, // 可选择？
              hasBorders: false, // 有边框？
              hasControls: false, // 是否可操作
              evented: false, // 是否绑定事件
            }
          );
          // 显示在画布
          this.c.add(mouseLine1);

          // mouseline2是鼠标按下拖动时出现的控制线
          if (mouseLine2) this.c.remove(mouseLine2);

          // x , y 是线段1的镜象线的坐标值,也就是线段2的末尾值  至于为什么x的值为x = 2 * originX - moveX,画个坐标出来就清楚了
          x = 2 * pointArray[len - 1].left - e.pointer.x;
          y = 2 * pointArray[len - 1].top - e.pointer.y;
          mouseLine2 = new fabric.Line(
            [pointArray[len - 1].left, pointArray[len - 1].top, x, y],
            {
              strokeWidth: 2,
              fill: "#999999",
              stroke: "#999999",
              class: "line",
              originX: "center",
              originY: "center",
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
            }
          );
          // 显示在画布
          this.c.add(mouseLine2);
        }
      });
      this.c.on("mouse:up", (e) => {
        // 鼠标弹起设置为false
        isDown = false;
        // 获取数组长度
        let len = pointArray.length;
        // 如果要绘画的是贝塞尔曲线，则需要确认起始点，结束点和控制点
        // 我们把数组中的len-2设置为起始点
        // 把控制点的type设置为空字符串
        // 移除2个控制线
        // lastIndex 用来操作第一个圆的特殊情况
        if (isBezier) {
          if (len >= 2) {
            pointArray[len - 2].lineType = "M";
            let temp = pointArray[len - 1];
            pointArray[len - 1] = bezierObj;
            temp.lineType = "";
            pointArray.push(temp);
            this.c.remove(mouseLine1);
            this.c.remove(mouseLine2);
            console.log("pointArray", pointArray, lastIndex);
            lastIndex -= 1;
          }
          isBezier = false;
        }
        for (let i = lastIndex; i < pointArray.length; i++) {
          console.log("type:", i, pointArray[i]);
          str +=
            " " +
            pointArray[i].lineType +
            " " +
            pointArray[i].left +
            " " +
            pointArray[i].top;
        }
        lastIndex = len;
        console.log("str:", str);
        // 清除上一次绘画的残留
        if (path) {
          this.c.remove(path);
        }
        path = new fabric.Path(str);
        path.set({
          fill: "transparent",
          stroke: "#1a80ff",
          selectable: true,
          hasBorders: false,
          hasControls: false,
          evented: false,
        });
        this.c.add(path);
        // 用数组中最后一个圆和第一个圆的id做比对 如果是相同的则代表是闭合路径，需要情况绘画字符串
        if (
          e.target &&
          pointArray.length > 2 &&
          e.target.id == pointArray[0].id
        ) {
          console.log("mm");
          this.c.remove(circle);
          str = "";
          pointArray = [];
          path = "";
        }
      });
    },

    // 文字
    useText() {
      let isFirst = true,
        text;
      // 鼠标按下在鼠标对应位置生成一个文字输入框
      //  需要区分是否是第一次点击或者第二次点击，第一次生成文本框
      this.c.on("mouse:down", (e) => {
        if (isFirst) {
          text = new fabric.IText("example", {
            left: e.pointer.x,
            top: e.pointer.y,
          });
          this.c.add(text);
          // 设置文本对象为可输入聚焦
          text.enterEditing();
          text.hiddenTextarea.focus();
          isFirst = false;
        } else {
          text.exitEditing();
          text.set("backgroundColor", "rgba(0,0,0,0)");
          this.removeMouseListener();
        }
      });
    },
    useCircle() {
      this.removeMouseListener();
      this.c.selectionColor = "transparent"; // 选框填充色：透明
      this.c.selectionBorderColor = "transparent"; // 选框边框颜色：透明度很低的黑色（看上去是灰色）
      this.c.skipTargetFind = true; // 禁止选中
      let currentType = "circle"; // 当前操作模式（默认 || 创建圆形）
      let downPoint = null; // 按下鼠标时的坐标
      let upPoint = null; // 松开鼠标时的坐标
      let currentCircle = null; // 临时圆，创建圆的时候使用

      this.c.on("mouse:down", (e) => {
        downPoint = e.absolutePointer;
        if (currentType === "circle") {
          // 使用 Fabric.js 提供的api创建圆形，此时圆形的半径是0
          currentCircle = new fabric.Circle({
            top: downPoint.y,
            left: downPoint.x,
            radius: 0,
            fill: "transparent",
            stroke: "rgba(0, 0, 0, 0.2)",
          });

          this.c.add(currentCircle);
        }
      });
      this.c.on("mouse:move", (e) => {
        if (currentType === "circle" && currentCircle) {
          const currentPoint = e.absolutePointer;

          // 半径：用短边来计算圆形的直径，最后除以2，得到圆形的半径
          let radius =
            Math.min(
              Math.abs(downPoint.x - currentPoint.x),
              Math.abs(downPoint.y - currentPoint.y)
            ) / 2;
          // 计算圆形的top和left坐标位置
          let top =
            currentPoint.y > downPoint.y
              ? downPoint.y
              : downPoint.y - radius * 2;
          let left =
            currentPoint.x > downPoint.x
              ? downPoint.x
              : downPoint.x - radius * 2;

          // 分别设置圆形的半径、top和left
          currentCircle.set("radius", radius);
          currentCircle.set("top", top);
          currentCircle.set("left", left);

          this.c.requestRenderAll();
        }
      });

      this.c.on("mouse:up", (e) => {
        upPoint = e.absolutePointer;

        if (currentType === "circle") {
          // 如果鼠标点击和松开是在同一个坐标，那就不会创建圆形（其实是把刚创建半径为0的圆形删掉）
          if (JSON.stringify(downPoint) === JSON.stringify(upPoint)) {
            this.c.remove(currentCircle);
          } else {
            if (currentCircle) {
              // 创建圆形（其实是把圆形边框的颜色改成 #000
              currentCircle.set("stroke", "#000");
            }
          }
          // 完成以上操作后，临时的圆形清空掉。
          currentCircle = null;
        }
      });
    },
    // 重置画布
    reset() {
      console.log("restet");
      // 获取画布上所有对象
      let children = this.c.getObjects();
      if (children.length > 0) {
        // 移除
        this.c.remove(...children);
      }
      // 移除所有监听事件
      this.removeMouseListener();
    },
    getObjects() {
      console.log("getObjects", this.c.getObjects());
    },
  },
};
</script>

<style scoped>
#c {
  border: 1px solid #000;
}
.container {
  display: flex;
  justify-content: space-between;
  width: 1024px;
  height: 1440px;
  border: solid 1px #ccc;
  margin-top: 16px;
}
.options {
  width: 100px;
  border: solid 1px #ccc;
}
.option {
  /* width: 100px; */
  height: 40px;
  background-color: #ccc;
  line-height: 40px;
  margin-top: 16px;
  cursor: pointer;
  text-align: center;
}
.option:active {
  background-color: bisque;
}
.attributes {
  width: 300px;
  border: solid 1px #ccc;
}
</style>
